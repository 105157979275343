.set-title {
    position: relative;
    bottom: 100px;
    left: 50px;
    font-weight: 400px;
    font-size: 57px;
}

.personal-title {
    font-weight: 700;
    font-size: 32px;
    color: #013B63
}

#add-address-btn {
    background-color: var(--mdBlue);
    font-weight: bold;
    font-weight: 600;
    font-size: 15px;
}

.main-add-title {
    font-weight: 600;
    font-size: 22px;
    color: var(--mdBlue)
}

.address-box{
    background-color: #F5F5F5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}