
.logo-font {
	font-family: "Anton", sans-serif;
	letter-spacing: 3.5px;
}
.bg-blue {
	background-color: var(--blue);
	padding: 0 90px;
}

li {
	list-style-type: none;
}

.line {
	width: 2px;
	height: 20px;
	background-color: white;
}

.navbar a {
	color: white !important;
}
.navbar .dropdown-menu a {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
  color: #012a4a !important;
}

.fsSmall {
	font-size: 13px;
}

#fade-menu .MuiPaper-root {
  position: absolute !important;
  background-color: #01497c !important;
  left: 0 !important;
  top: 0 !important;
  min-height: 100vh !important;
}

#fade-menu .MuiList-root {
  height: 100% !important;
}



.burger {
  display: none;
}
@media (max-width: 1024px) {
  .webNav{
    display: none;
  }
  .burger {
    display: flex;
  }
}
