.volvo-pic {
    margin-top: -80px;
    margin-left: 15px;
    width: 192px;
    height: 192px;
}

.supplier-title {
    font-weight: 700;
    font-size: 32px;
    color: var(--darkblue);
}

.supplier-desc {
    font-weight: 400;
    font-size: 16px;
    color: var(--lightGray);
    width: 650px;
}

.rate-stars {
    font-weight: 600;
    font-size: 56.4096px;
    color: var(--darkblue);
}

.num-reviews {
    font-weight: 400;
    font-size: 10.5768px;
    color: var(--lightGray);
    padding-top: 10px;
}

.tabs-view {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tab-title-div {
    font-weight: 600;
    font-size: 24.2945px;
    color: var(--blue);
}

.title-content {
    font-weight: 700;
    font-size: 32px;
}

.title-bold-contact {
    font-weight: 700;
    font-size: 20px;
}

.overview-text {
    font-weight: 700;
    font-size: 32px;
    color: var(--blue);
}

.page-name-text{
    font-weight: 700;
    font-size: 30px;
    color: var(--blue);
}

.page-body-text{
    font-size: 15px;
    color: var(--lightGray);
}