p {
    padding: 0;
    margin: 0
}

h3 {
    padding: 0;
    margin: 0
}

.cat-title {
    color: var(--lightBlue);
    text-decoration: underline;
    font-size: 12px;
}

.cat-title:hover {
    cursor: pointer;
}

.product-title {
    color: var(--darkblue);
}

.quntity-box {
    background-color: rgba(42, 111, 152, 0.15);
    border-radius: 5px;
}

#btn-box {
    background-color: var(--blue);
    width: 300px;
    height: 50px
}

.btn-box:hover {
    cursor: pointer;
    background-color: var(--blue);
    width: 300px;
    height: 50px
}

.tab-container {
    box-shadow: 0px -2px 10px rgba(1, 73, 124, 0.15);
    border-radius: 7px;
}

.tab {
    background-color: var(--blue);
    color: "#fff";
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    width: 100%;
    height: 60px;
}

.active-tab {
    background-color: '#fff';
}

.tab:hover {
    cursor: pointer;
}

.active-tab:hover {
    cursor: pointer;
}



.title-details {
    color: var(--blue);
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.modal-title {
    background-color: var(--mdBlue);
    display: flex;
    justify-content: center;
    align-items: center;
    color: '#fff';
    width: 159.27px;
    height: 53.55px;
    border-radius: 8.8px;
}

.modal-body {
    background: rgba(42, 111, 152, 0.15);
    border-radius: 8.29553px;
    display: flex;
    align-items: center;
    width: 676.09px;
    height: 51.55px;
}


.gallery-thumbnails {
    height: 375px;
    width: 30%;
    overflow-y: auto;
}

.gallery-box::-webkit-scrollbar {
    display: none;
}

.reviews-box::-webkit-scrollbar{
    display: none;
}

.img-box {
    display: block;
    width: 100%;
    height: 115px;
    object-fit: cover;
    margin-bottom: 1rem;
    padding-right: 1rem;
}

.img-box:hover {
    cursor: pointer;
}

.gallery-img {
    width: 70%;
    height: 375px;
    object-fit: cover;
}
.gallery-img img {
    width: 100%;
    height: 100%;
}

.years-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 189px;
    height: 185px;
    border-radius: 10px;
}

.num-title {
    font-weight: 700;
    font-size: 70px;
}

.year-title {
    font-weight: 500;
    font-size: 48px;
}

.cer-title {
    font-weight: 500;
    font-size: 22px;
    color: var(--darkblue);
}

.exp-title {
    font-weight: 500;
    font-size: 15px;
    color: var(--darkblue);
}

.cer-comp {
    font-weight: 400;
    font-size: 17px;
}

  
  .pro-over-title{
    color: var(--blue);
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }

  .pro-over-title:hover{
    cursor: pointer;
  }

  .over-details{
    background: #FFFFFF;
    border: 0.5px solid #F8F9FA;
    box-shadow: 0px -1px 10px rgba(1, 73, 124, 0.15);
    border-radius: 10px;
    padding: 20px;
    height: 500px;
    overflow: auto;
  }

  .btn-variant{
    background: var(--mdBlue);
    border: 1px solid #F8F9FA;
    box-sizing: border-box;
    border-radius: 10px;
    width: 192px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: '#fff';
    font-weight: 700;
    font-size: 16px;
  }
