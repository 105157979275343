.profile-title {
    position: absolute;
    bottom: 30px;
    left: 50px;
    font-weight: 400px;
    font-size: 57px;
}

.profile-box {
    background: #FFFFFF;
    border: 1px solid #F8F9FA;
    box-shadow: 0px 5px 10px rgba(1, 73, 124, 0.15);
}

.edit-box {
    position: absolute;
    right: 25px;
    bottom: 10px;
    background-color: #2A6F98;
    padding: 10px;
}

.edit-box:hover {
    cursor: pointer;
}

.title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: #01497C;
}

.company-box {
    background-color: var(--darkblue);
    height: 55px;
}

.company-pic {
    width: 100px;
    height: 100px
}

#logout-btn {
    background-color: #E07070;
    font-weight: bold;
}

.active-tab {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    border: 0.554783px solid #F8F9FA;
    box-shadow: 0px -2px 10px rgba(1, 73, 124, 0.15);
}

.active-tab:hover {
    cursor: pointer;
}

.active-comp {
    background: #FFFFFF;
    border: 0.691358px solid #F8F9FA;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px -2px 10px rgba(1, 73, 124, 0.15);
}

.title-text {
    font-weight: 600;
    font-size: 18px;
    color: var(--blue)
}

.view-all-btn{
    background-color: #cc3363;
    font-weight: 500;
    color: #fff;
}

.edit-btn {
    background-color: var(--lightBlue) !important;
    font-weight: 500;
    color: #fff !important;
}

.edit-btn:hover{
    filter: opacity(0.9);
}

.delete-btn{
    background-color: #E07070 !important;
    font-weight: 500;
    color: #fff !important;

}

.delete-btn:hover{
    background-color: #E07070 !important;
    filter: opacity(0.9);

  
}

.reviews-list{
    height: 600px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.reviews-list::-webkit-scrollbar {
    display: none;
}

.upload-btn{
    background-color: '#fff';
    font-weight: 500;
    color: #01497C; 
    border: 1px solid #01497C;
}

.upload-btn:hover{
    background-color: '#fff';
    font-weight: 500;
    color: #01497C; 
    border: 1px solid #01497C;
}

.steps-box{
    background: #FFFFFF;
    border: 0.571701px solid #F8F9FA;
    box-shadow: 0px 5px 10px rgba(1, 73, 124, 0.1);
}

.Mui-completed {
    color: #7ACC66 !important;
}

.Mui-active{
    color: #01497C !important;
}

.MuiStepLabel-label{
    color: #01497C !important;
}